import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from '@quantum-theme/core/_config/api/api';
import { Observable } from 'rxjs';
import { ResponseModel } from '@_core/models/response';
import { QueryUtilsModel } from '@_core/models/logic-custom-query/query-utils.model';
import { QueryCriteria } from '@_core/models/logic-custom-query/query-criteria.model';
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';

const corporateAPI = API.corporate;
const formManagementAPI = API.formManagement;


@Injectable()
export class CorporateService {
  constructor(
    private http: HttpClient,
  ) { }

  // 拿出登入者部門與登入者部門之子部門
  queryCorporate(query: string, topOrgId?: number): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 50
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('top_org_id', topOrgId.toString(), '=', 'and', true)] : [])
    ];

    return this.http.post<ResponseModel>(corporateAPI + '/list/table', queryUtils);
  }

  // 组织架构上级组织架构使用
  queryOrgCorporate(query: string, topOrgId?: number, selected?: string): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 99
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('top_org_id', topOrgId.toString(), '=', 'and', true)] : []),
      ...([new QueryCriteria('status', '_SYS_A9_1', 'like', 'and')]),
    ];

    if (selected) {
      queryUtils['params'] = {selectedOrg: selected};
    }

    return this.http.post<ResponseModel>(corporateAPI + '/list/table', queryUtils);
  }

  // 拿出全部的組織架構
  queryAllCorporate(query: string, topOrgId?: number): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 50
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('top_org_id', topOrgId.toString(), '=', 'and', true)] : [])
    ];

    return this.http.post<ResponseModel>(corporateAPI + '/allList', queryUtils);
  }

  queryCompany(query: string, topOrgId?: number): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 50
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      new QueryCriteria('org_type', corporateStructureType.company, 'like', 'and'),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('top_org_id', topOrgId.toString(), '=', 'and', true)] : [])
    ];

    return this.http.post<ResponseModel>(corporateAPI + '/list/table', queryUtils);
  }

  queryDepartment(query: string, topOrgId?: number): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 50
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      new QueryCriteria('org_type', corporateStructureType.department, 'like', 'and'),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('top_org_id', topOrgId.toString(), '=', 'and', true)] : [])
    ];

    return this.http.post<ResponseModel>(corporateAPI + '/list/table', queryUtils);
  }

  queryFacility(query: string, topOrgId?: number): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 50
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      new QueryCriteria('org_type', corporateStructureType.facility, 'like', 'and'),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('top_org_id', topOrgId.toString(), '=', 'and', true)] : [])
    ];

    return this.http.post<ResponseModel>(corporateAPI + '/list/table', queryUtils);
  }

  queryTopOuidList(query: string, topOrgId?: number): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 50
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
    ];

    return this.http.post<ResponseModel>(corporateAPI + '/topOuidList', queryUtils);
  }

  queryActiveCompany(query: string): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 50
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...[new QueryCriteria('org_type', SystemCodeMap.corporateStructureType.company, '=', 'and')],
      ...[new QueryCriteria('status', SystemCodeMap.status.active, '=', 'and')]
    ];

    return this.http.post<ResponseModel>(corporateAPI + '/allList', queryUtils);
  }
  // emergency Dashboard 接口
  queryBusinessLineDashboard(query: string, topOrgId?: number): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 99
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      new QueryCriteria('org_type', corporateStructureType.department, '=', 'and'),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('parent_id', topOrgId.toString(), 'in', 'and', true)] : []),
    ];

    return this.http.post<ResponseModel>(corporateAPI + '/list/table/dashboard', queryUtils);
  }
  // 大区
  queryDivision(query: string, topOrgId?: string): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 9999
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };

    queryUtils.queryCriterias = [
      new QueryCriteria('org_type', corporateStructureType.division, '=', 'and'),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(topOrgId ? [new QueryCriteria('parent_id', topOrgId.toString(), 'in', 'and', true)] : [new QueryCriteria('parent_id', '-1', 'in', 'and', true)])
    ];
    return this.http.post<ResponseModel>(corporateAPI + '/list/table', queryUtils);
  }
 // 区域
 queryRegion(query: string, topOrgId?: string): Observable<ResponseModel> {
  const { corporateStructureType } = SystemCodeMap;
  const queryUtils: QueryUtilsModel = {
    pagingTool: {
      currentPage: 1,
      pageSize: 9999
    },
    queryOrderBies: [
      {
        columnName: 'code',
        orderType: 'asc'
      }
    ]
  };

      queryUtils.queryCriterias = [
        new QueryCriteria('org_type', corporateStructureType.region, '=', 'and'),
        ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
        ...(topOrgId ? [new QueryCriteria('parent_id', topOrgId.toString(), 'in', 'and', true)] : [new QueryCriteria('parent_id', '-1', 'in', 'and', true)])
      ];

      return this.http.post<ResponseModel>(corporateAPI + '/list/table', queryUtils);
    }
  // 项目
  queryGroups(query: string, topOrgId?: string, inRanking?, business_line_id?): Observable<ResponseModel> {
    const { corporateStructureType } = SystemCodeMap;
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 99
      },
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'asc'
        }
      ]
    };
    if (topOrgId !== '-666') {
      queryUtils.queryCriterias = [
        new QueryCriteria('org_type', corporateStructureType.group, '=', 'and'),
        ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
        ...(inRanking ? [new QueryCriteria('in_ranking', 1, '=', 'and')] : []),
        ...(topOrgId ? [new QueryCriteria('parent_id', topOrgId.toString(), 'in', 'and', true)] : [new QueryCriteria('parent_id', '-1', 'in', 'and', true)])
      ];
    } else {
      queryUtils.queryCriterias = [
        new QueryCriteria('org_type', corporateStructureType.group, '=', 'and'),
        ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
        ...(business_line_id ? [new QueryCriteria('tt.business_line_id', business_line_id, '=', 'and')] : []),
      ];
    }

    return this.http.post<ResponseModel>(corporateAPI + '/list/table', queryUtils);
  }
    // 获取当前用户的默认组织架构
    getDefaultOrg(): Observable<ResponseModel> {
      return this.http.get<ResponseModel>(corporateAPI + '/list/table/default/all');
    }

    getCheckTypeAndTable(tree_org_id): Observable<ResponseModel> {
      const queryUtils: QueryUtilsModel = {
        'queryCriterias': [
          {
            'connection': 'and',
            'key': 'org_id',
            'condition': 'in',
            'value': String(tree_org_id),
            'isValueADigital': true,
          }
        ]
      };
      return this.http.post<ResponseModel>(formManagementAPI + '/havingPreview/list/tree', queryUtils);
    }

}
