import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription, of } from 'rxjs';
import { map, tap, skipWhile, combineLatest } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import * as fromAuth from '@auth/reducers';
import * as AuthActions from '@auth/actions/auth';
import { AuthenticationService } from '@auth/services/authentication.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable()
export class AuthGuard implements CanActivate {
  auth$: Observable<any>;
  authSubscription: Subscription;
  permissionsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    private store: Store<fromAuth.State>,
    private router: Router,
    private authService: AuthenticationService,
    private permissionsService: NgxPermissionsService,
  ) {
    this.auth$ = this.store.pipe(select(fromAuth.selectAuthState));
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    // return of(true); // temp regulation
    return this.auth$.pipe(
      map(state => state.loggedIn),
      tap(loggedIn => {
        if (!loggedIn) {
          this.router.navigateByUrl('/auth/login');
        }
      }),
      combineLatest(
        this.loadPermissions(),
        this.loadAccessModules(),
        (loggedIn, userRoles, accessModules) => {
          if (loggedIn) {
            this.permissionsService.loadPermissions(userRoles.concat(accessModules));
            return true;
          }
          return false;
        })
    );
  }

  private loadPermissions() {
    this.authService.getAccessToken()
      .subscribe((token) => {
        if (token) {
          this.store.dispatch(AuthActions.LoginWithSavedToken({ token }));
        }
      });
    return this.authService.getUserRoles()
      .pipe(
        skipWhile(state => state.length <= 0)
      );
  }

  private loadAccessModules() {
    return this.authService.getAccessModules()
      .pipe(
        skipWhile(state => state.length <= 0)
      );
  }
}
