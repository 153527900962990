import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from '@quantum-theme/core/_config/api/api';
import { Observable } from 'rxjs';
import { ResponseModel } from '@_core/models/response';
import { QueryUtilsModel } from '@_core/models/logic-custom-query/query-utils.model';
import { QueryCriteria } from '@_core/models/logic-custom-query/query-criteria.model';

// Config
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';

const employeeAPI = API.employee;
const baseUrlAPI = API.baseUrl;

@Injectable()
export class EmployeeService {

  constructor(
    private http: HttpClient,
  ) { }

  // 拿出指定Employee
  getEmployee(id: string | number): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(API.employee + '/' + id);
  }

  // 拿出登入者部門與登入者部門之子部門的 Employee List
  getEmployeeList(queryUtil: QueryUtilsModel): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(employeeAPI + '/list', queryUtil);
  }

  // 拿出指定(或不指定就全部)部門與指定(或不指定就全部)部門之子部門的 Employee List，人員部門不合併
  getDivisionalList(queryUtil: QueryUtilsModel): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(employeeAPI + '/divisionalList', queryUtil);
  }

  // 拿出指定(或不指定就全部)部門與指定(或不指定就全部)部門之子部門的 Employee List，人員部門合併
  getAutocompleteList(queryUtil: QueryUtilsModel): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(employeeAPI + '/autocompletelist', queryUtil);
  }

  getTopOrgIdList(queryUtil: QueryUtilsModel): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(employeeAPI + '/parallelSignoff/divisionalList', queryUtil);
  }

  getTopOuidList(queryUtil: QueryUtilsModel): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(employeeAPI + '/topOuidList', queryUtil);
  }

  getCorpChildren(orgId: number[]): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(baseUrlAPI + '/corporates/allChildren', orgId);
  }

  private genQueryUtils(queryCriterias: QueryCriteria[]): QueryUtilsModel {
    return {
      pagingTool: {
        currentPage: 1,
        pageSize: -1,
      },
      queryCriterias: queryCriterias,
      queryOrderBies: [
        {
          columnName: 'code',
          orderType: 'desc'
        }
      ]
    };
  }

  queryEmployee(query?: string, orgId?: number): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils([
      ...(orgId ? [new QueryCriteria('org_ids', orgId.toString(), 'like')] : []),
      ...(query ? [new QueryCriteria('full_name', query, 'like', 'or')] : []),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : [])
    ]);
    return this.http.post<ResponseModel>(employeeAPI + '/list', queryUtils);
  }

  queryEmployeeWithCondition(query?: string, orgIds?: string, empTitle?: string, queryUpLevel?: string): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils([
      ...(orgIds ? [new QueryCriteria('org_ids', orgIds, '=')] : []),
      ...(query ? [new QueryCriteria('full_name', query, 'like', 'or')] : []),
      ...(query ? [new QueryCriteria('code', query, '=', 'and')] : []),
      ...(empTitle ? [new QueryCriteria('titles', empTitle, '=', 'and')] : []),
      // ...(Number(queryUpLevel) === 1 || Number(queryUpLevel) === 0 ? [new QueryCriteria('query4Cor', queryUpLevel, '=', 'and')] : []),
    ]);
    return this.http.post<ResponseModel>(employeeAPI + '/list/condition', queryUtils);
  }

  // TODO: Entry '/listMin' returns a minified employee DTOs 'EmployeeMin' with special columns needs to be unified.
  queryEmployeeMin(query?: string): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = {
      pagingTool: {
        currentPage: 1,
        pageSize: 20,
      },
      queryCriterias: [
        ...(query ? [new QueryCriteria('employee_name', query, 'like', 'and')] : []),
        ...(query ? [new QueryCriteria('employee_code', query, 'like', 'or')] : []),
      ],
      queryOrderBies: [
        {
          columnName: 'employee_code',
          orderType: 'desc'
        }
      ]
    };
    return this.http.post<ResponseModel>(employeeAPI + '/listMin', queryUtils);
  }

  queryFullTime(query?: string): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils([
      new QueryCriteria('contractor', '0', '=', 'and', true),
      ...(query ? [new QueryCriteria('full_name', query, 'like', 'or')] : []),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : [])
    ]);
    return this.http.post<ResponseModel>(employeeAPI + '/list', queryUtils);
  }

  queryContractor(query?: string): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils([
      new QueryCriteria('employee_type', SystemCodeMap.jobTaskStaffType.contractor, '=', 'and', false),
      new QueryCriteria('contractor', '1', '=', 'and', true),
      ...(query ? [new QueryCriteria('full_name', query, 'like', 'or')] : []),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : [])
    ]);
    return this.http.post<ResponseModel>(employeeAPI + '/list', queryUtils);
  }

  queryDivisional(query?: string, orgId?: number): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils([
      ...(orgId ? [new QueryCriteria('top_org_id', orgId.toString(), '=')] : []),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : [])
    ]);
    return this.http.post<ResponseModel>(employeeAPI + '/divisionalList', queryUtils);
  }

  queryAutocompleteList(query?: string, orgId?: number, queryUpLevel?: string): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils([
      ...(orgId ? [new QueryCriteria('top_org_id', orgId.toString(), '=')] : []),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(Number(queryUpLevel) === 1 || Number(queryUpLevel) === 0 ? [new QueryCriteria('query4Cor', queryUpLevel, '=', 'and')] : []),
    ]);
    return this.http.post<ResponseModel>(employeeAPI + '/autocompletelist', queryUtils);
  }

  queryEmployeeWithConditionEmergecy(query?: string, orgId?: number, empTitle?: string, queryUpLevel?: string): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils([
      ...(orgId ? [new QueryCriteria('top_org_id', orgId.toString(), '=')] : []),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      ...(empTitle ? [new QueryCriteria('scc.title', empTitle, '=')] : []),

      ...(Number(queryUpLevel) === 1 || Number(queryUpLevel) === 0 ? [new QueryCriteria('query4Cor', queryUpLevel, '=', 'and')] : []),
    ]);
    return this.http.post<ResponseModel>(employeeAPI + '/autocompletelist', queryUtils);
  }

  // 轉派使用
  queryAllOrgsEmployee(query?: string, orgId?: number): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils([
      ...(orgId ? [new QueryCriteria('org_ids', orgId.toString(), 'like')] : []),
      ...(query ? [new QueryCriteria('full_name', query, 'like', 'or')] : []),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : [])
    ]);
    return this.http.post<ResponseModel>(employeeAPI + '/allOrgsEmployeeList', queryUtils);
  }

  queryAllOrgsContact(query?: string, orgId?: number): Observable<ResponseModel> {
    const queryUtils: QueryUtilsModel = this.genQueryUtils([
      ...(orgId ? [new QueryCriteria('t.org_id', orgId.toString(), '=')] : []),
      ...(query ? [new QueryCriteria('full_name', query, 'like', 'or')] : []),
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : [])
    ]);
    return this.http.post<ResponseModel>(employeeAPI + '/allOrgsContactList', queryUtils);
  }

  getEmployeeByOrgGroup(org?: any[], group?: any[]) {
    const query = {
      org: [],
      group: []
    };
    if (org && org.length > 0) {
      query.org = org;
    } else {
      delete query.org;
    }
    if (group && group.length > 0) {
      query.group = group;
    } else {
      delete query.group;
    }
    return this.http.post<ResponseModel>(baseUrlAPI + '/message/loadUsers', query);
  }

  queryEmployeeSignTable(queryUtils): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(employeeAPI + '/autocompletelist/titles', queryUtils);
  }
}
