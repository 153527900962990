// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const DEPLOY = document.domain.toLowerCase().indexOf('localhost') !== -1 ? 'dev' : 'distribution';
const str = document.baseURI;

const FETCH = {
  'distribution': {
    production: false,
    isMockEnabled: false, // You have to switch this, when your real back-end is done
    authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
    disableRealAPI: false,
    baseUrl: str + 'facts_backend-2.6/rest',
    ehsOrgId: 35,
    infirmaryId: 400,
  },
  'dev': {
    production: false,
    isMockEnabled: false, // You have to switch this, when your real back-end is done
    authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
    disableRealAPI: false,
    baseUrl: 'https://quantum-dev.usequantum.com.cn/facts_backend-2.6/rest',
    ehsOrgId: 35,
    infirmaryId: 400,
  },
};

export const environment = FETCH[DEPLOY];
export const baseURI = document.baseURI;
