import { createReducer, on, Action } from '@ngrx/store';
import * as AuthActions from '../actions/auth';

export interface State {
  token: string;
  userName: string;
  firstName: string;
  lastName: string;
  userId: number;
  orgId: string;
  employeeId: number;
  userRoles: string[];  // role code in original userRole
  userDetailRoles: any[];  // original userRole object
  loggedIn: boolean;
  permissionList: string[];
  phone: string;
  config: any
}

export const initialState: State = {
  token: null,
  userName: null,
  firstName: null,
  lastName: null,
  userId: null,
  orgId: null,
  userRoles: [],
  userDetailRoles: [],
  loggedIn: false,
  permissionList: [],
  phone: null,
  employeeId: null,
  config: null,
};

export const authReducer = createReducer(
  initialState,
  on(AuthActions.LoginSuccess, (state, { auth }) => {
    const { token, userName, firstName, lastName, userId, userRoles, phone, employeeId, orgId } = auth;
    return {
      ...state,
      token,
      userName,
      firstName,
      lastName,
      userId,
      orgId,
      userRoles: userRoles.map(v => v.code),
      userDetailRoles: userRoles,
      loggedIn: true,
      phone,
      employeeId: employeeId,
    };
  }),
  on(AuthActions.LoginFailure, (state, { msg }) => ({ ...initialState })),
  on(AuthActions.LoginWithSavedToken, (state, { token }) => ({
    ...state,
    token,
    loggedIn: true
  })),
  on(AuthActions.GetUserDetailFromTokenSuccess, (state, { payload }) => {
    const { userName, firstName, lastName, userRoles, userId, phone, employeeId, orgId } = payload;
    return {
      ...state,
      userName,
      firstName,
      lastName,
      userId,
      orgId,
      userRoles: userRoles.map(v => v.code),
      userDetailRoles: userRoles,
      phone,
      employeeId: employeeId,
    };
  }),
  on(AuthActions.Logout, (state) => ({ ...initialState })),
  on(AuthActions.GetUserPermissionListSuccess, (state, { res }) => {
    return {
      ...state,
      permissionList: res
    }
  }),
  on(AuthActions.GetSystemConfigSuccess, (state, { res }) => {
    return {
      ...state,
      config: res
    }
  })
);

export function reducer(state: State | undefined, action: Action) {
  return authReducer(state, action);
}
