// Simplified Chinese
export const locale = {
  lang: 'zh-Hans',
  data: {
    MENU: {
      TASK_DETAIL: '任务详情',
      TASK_CONTENT: '任务内容',
      DASHBOARD: '仪表板',
      DASHBOARD_REP: '模组报表',
      DASHBOARD_TRA: '培训仪表板',
      DASHBOARD_REG: '法规仪表板',
      DASHBOARD_RIS: '风险仪表板',
      DASHBOARD_INC: '事故仪表板',
      DASHBOARD_ISS: '隐患仪表板',
      DASHBOARD_EME: '应急仪表板',
      SYSTEM_SETUP: '系统设定',
      TAS110: '个人任务中心',
      TAS111: '任务管理',
      TAS112: '待认领任务',
      TAS113: '我的任务-试用',
      TRA100: '培训与证件',
      TRA110: '培训计划',
      TRA111: '培训日历',
      TRA112: '部门培训',
      TRA113: '教师管理',
      TRA114: '班级排课',
      TRA115: '培训日历',
      TRA116: '豁免工具',
      TRA117: '员工记录',
      TRA118: '课程记录',
      TRA119: '我的课程',
      TRA120: '证件管理',
      TRA121: '证件查询与录入',
      TRA122: '证件名称维护',
      TRA130: '培训记录',
      TRA131: '待完成培训',
      TRA132: '已完成培训',
      TRA133: '会议签到',
      TRA134: '我的课程',
      TRA135: '员工记录',
      TRA140: '课程管理',
      TRA141: '课程管理',
      TRA142: '试卷管理',
      TRA143: '试题集管理',
      TRA144: '培训计划',
      RIS100: '风险评估',
      RIS110: '风险分析',
      RIS111: '风险矩阵',
      RIS112: '作业风险查询',
      RIS120: '风险控制',
      RIS121: '风险评估计划',
      RIS130: '风险纪录',
      RIS131: '风险评估任务',
      RIS132: '整改措施',
      RIS140: '两单三卡',
      RIS141: '安全风险分级管控清单',
      RIS142: '危险因素排查辨识清单',
      RIS143: '三卡管理',
      EME100: '应急管理',
      EME110: '应急资源管理',
      EME111: '应急队伍',
      EME112: '应急物资',
      EME120: '应急预案',
      EME121: '应急预案库',
      EME122: '应急预案',
      EME130: '应急演练',
      EME131: '应急演练计划',
      EME132: '应急演练记录',
      EME134: '应急演练计划详情',
      EME133: '应急演练评估',
      INC100: '事故管理',
      INC110: '事故处理',
      INC111: '事故通报',
      INC112: '事故查询',
      INC113: '事故调查',
      INC114: '整改措施',
      INC120: '劳动统计',
      INC121: '每月工时填报',
      INC122: '法定工作日纪录',
      INC123: '工时统计報表',
      GR100: '法规鉴别',
      GR110: '法规资料库',
      GR111: '法规总览',
      GR112: '草案总览',
      GR113: '我的最爱',
      GR114: '公司适用清单',
      GR115: '上传更新法规',
      GR120: '鉴别与改善',
      GR121: '查核活动',
      GR122: '鉴别任务',
      GR123: '整改措施',
      SS100: '系统设定',
      SS110: '基本设定',
      SS111: '组织架构',
      SS112: '员工管理',
      SS113: '工厂管理',
      SS116: '角色管理',
      SS120: '进阶设​​定',
      SS121: '系统代码',
      SS130: '表格管理',
      SS131: '题库',
      SS132: '表格',
      SS140: '通知管理',
      SS141: '公告管理',
      SS142: '空间点管理',
      SS143: '物理空间管理',
      INS100: '检查管理',
      INS110: '检查活动',
      INS111: '隐患上报',
      INS112: '计划检查',
      INS113: '立即检查',
      INS120: '检查结果',
      INS121: '检查纪录',
      INS122: '隐患列表',
      INS123: '整改措施',
      INS130: '政府检查',
      INS131: '政府检查上报',
      INS132: '政府检查查询',
      INS133: '政府处罚',
      INS134: '政府检查整改查询',
      DOC100: '合规文件管理',
      DOC110: '合规文件管理',
      DOC111: '合规文件管理',
      DOC112: '合规文件清单',
      DOC113: '合规文件分类',
      NM100: '通知管理',
      NM110: '信息管理',
      NM111: '消息中心',
      SS114: '员工群组',
      SS115: '公告管理',
      INC125: '消息中心',
      SCREEN_DASHBOARD: '大屏报表',
      DATA_OVERVIEW: '数据统计概览',
    },
    GENERAL: {
      ACTIONS: {
        LOGOUT: '注销',
        OK: '确认',
        CANCEL: '取消',
        CLOSE: '关闭',
        LEAVE: '离开',
        SUBMIT: '提交',
        RESET: '重置',
        SEARCH: '搜索',
        VIEW: '查看',
        ADD: '新增',
        EDIT: '编辑',
        EXPORT_ALL: '全部导出',
        EXPORT: '导出',
        DELETE: '删除',
        UPDATE: '更新',
        SAVE: '保存',
        DO_NOT_SAVE: '不保存',
        SAVE_CONFIRM: '保存并确认',
        SAVE_SUBMIT: '保存并提交',
        SAVE_LEAVE: '保存并离开',
        BACK: '返回',
        PREVIOUS: '上一步',
        NEXT: '下一步',
        NO: '否',
        YES: '是',
        CLEAR_SELECTION: '无',
        BACK_TO_LIST: '回列表页',
        PUBLISH: '发布',
        CHOOSE: '选择',
        CLEAR: '清除',
        ADD_NEW_VERSION: '发布',
        ADVANCED_SEARCH_SHOW: '显示高级搜索',
        ADVANCED_SEARCH_HIDE: '隐藏高级搜索',
        SUBMIT_PREVIEW: '提交预览',
        CLAIM: '认领',
        ASSIGN: '转派',
        EXPORT_EXCEL: '导出Excel',
        DRAFT: '保存草稿',
        CLOSE_CASE: '结案',
        REJECT: '驳回',
        SHUTDOWN: '终止',
        END: '结束',
        PRINT: '打印',
        COLUMN_SELECTOR: '栏位',
        BACK_HOME: '返回首页',
        RELEASE: '发布',
        SIGNOFFTASK: '签核任务'
      },
      TITLE: {
        DELETE: '确认删除',
        LEAVE: '确认离开?',
        SURE: '确认清除回报',
        COLUMN_SELECTOR: '选择显示栏位',
        RELEASE: '确认发布',
      },
      MSG: {
        RELEASE: '确定要发布？',
        DELETE: '删除后记录将会清空且无法复原，确定要删除？ ',
        LEAVE: '您尚未保存当前内容，请问您确定离开？ ',
        CONFIRM_LEAVE: '当前页面可能存在未保存内容，您确定离开吗？',
        SURE: '确认清空事故回报吗？ ',
        UNFILLED_FIELD: '无法保存，有尚未填写的输入框',
        UNFILLED_FIELD_SUBMIT: '无法提交，有尚未填写的输入框',
        FIELD_EMPTY: '必填项未填写，请补全',
        CREATE_SUCCESS: '添加成功',
        UPDATE_SUCCESS: '更新成功',
        DELETE_SUCCESS: '删除成功',
        SAVE_SUCCESS: '保存成功',
        SUBMIT_SUCCESS: '提交成功',
        SEARCH_NECESSARY: '必填的搜索项未选择，无法搜索！',
        PUBLISH_SUCCESS: '发布成功',
        CLOSE_SUCCESS: '终止成功',
        PAGE_404: '抱歉，您访问的页面不存在，请联系管理员。',
      },
      NONE: '无',
      NO_RESULT: '没有结果',
      PLACEHOLDER: {
        SEARCH: '输入搜索',
        SELECT: '请选择',
        CLARIFY: '请说明',
      },
      SELECT_REQUIRED: '请选择一项',
      CHILDREN_ORG_INCLUDED: '包含子架构',
      NO_ATTACHMENTS: '未上传附件'
    },
    LABEL: {
      CHECKBOX_SELECTED: '已选择'
    },
    DX: {
      NO_DATA_TEXT: '无资料',
      PAGER: {
        INFOTEXT: '第 {0} 页，共 {1} 页 (共 {2} 项)'
      },
      UPLOADER: {
        SELECT_BTN_TEXT: '选择附件',
        LABEL_TEXT: '或拖动附件至此',
        READY_MSG: '准备上传',
        MAX_FILE_SIZE: '附件大小限制',
        INVALID_MAX_FILE_SIZE_MSG: '附件过大',
        IGNORE_OVERLARGE_FILE_HINT: '过大的附件将不会上传',
        RESET: '清除已选附件',
        DELETE: '删除附件',
        CONFIRM_DELETE: '您确定要删除此附件吗？ ',
        FAILED_TO_UPLOAD: '未能成功上传',
        IMPORT: '导入',
        PICTURE_HINT: '请上传jpg, jpeg, gif, png格式的图片,建议图片大小不超过2M',
        FILE_INVALID: '上传的文件不符合要求，请重新选择',
        INVALID_FILE_EXTENSION_MSG: '文件格式不符合要求',
      }
    },
    MAT: {
      PAGINATOR: {
        ITEMS_PER_PAGE: '每页',
        FIRST: '第一页',
        LAST: '最后一页',
        PREVIOUS: '上一页',
        NEXT: '下一页',
      },
    },
    HTTP_ERROR_MSG: {
      '401': '认证失效，请重新登入',
      '404': '系统下线或维护中，请稍候再试',
      '413': '上传档案过大，请尝试较小档案'
    }
  }
};
