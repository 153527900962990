import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from '@quantum-theme/core/_config/api/api';
import { Observable, of } from 'rxjs';
import { ResponseModel } from '@_core/models/response';
import { QueryUtilsModel } from '@_core/models/logic-custom-query/query-utils.model';
import { QueryCriteria } from '@_core/models/logic-custom-query/query-criteria.model';
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';

const employeeAPI = API.employee;

@Injectable()
export class FacilityService {
  constructor(
    private http: HttpClient,
  ) { }

  getFacilityList(orgIds: number[]): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(employeeAPI + '/initData' + orgIds.toString() + '/facilities');
  }
}
