import { environment } from '@environments/environment';

const baseUrl = environment.baseUrl;

const api = {
  /** === AUTH === */
  login: baseUrl + '/permissions/login',
  permission: baseUrl + '/permissions',
  /** === SYSTEM === */
  facility: baseUrl + '/facilities',
  location: baseUrl + '/locations',
  code: baseUrl + '/codes',
  corporate: baseUrl + '/corporates',
  user: baseUrl + '/users',
  employee: baseUrl + '/employees',
  messageGroup: baseUrl + '/messageGroup',
  file: baseUrl + '/file',
  /** === SAFETY OBSERVATION === */
  observation: baseUrl + '/observation',
  /** === REGULATION === */
  govRegulation: baseUrl + '/govRegulation',
  govRegulationExplanation: baseUrl + '/govRegulationExplanation',
  govRegulationItem: baseUrl + '/govRegulationItem',
  govRegulationMasterOrg: baseUrl + '/govRegulationMasterOrg',
  govRegulationMasterUser: baseUrl + '/govRegulationMasterUser',
  govRegulationDraft: baseUrl + '/govRegulationDraft',
  identRegulationActivity: baseUrl + '/identRegulationActivity',
  /** === TRAINING === */
  training: baseUrl + '/training',
  aliyun: baseUrl + '/aliyun',
  /** === RISK === */
  riskAssessment: baseUrl + '/riskAssessment',
  activities: baseUrl + '/activities',
  taskCenter: baseUrl + '/tasks/taskCenter',
  taskCenterPersonalToDo: baseUrl + '/tasks/taskCenterPersonal',
  taskCenterPersonalToCollect: baseUrl + '/tasks/taskCenterCollection',

  taskCenterPersonalToDoNew: baseUrl + '/tasks/taskCenterPersonal',
  taskCenterPersonalToFinish: baseUrl + '/tasks/taskCenterPersonal',
  taskCenterPersonalHistory: baseUrl + '/tasks/taskCenterPersonalHistory',
  taskBpm: baseUrl + '/taskBpm',
  tasks: baseUrl + '/tasks',
  correctiveAction: baseUrl + '/tasks/correctiveAction',
  /** === EMERGENCY === */
  emergencyTeam: baseUrl + '/team/list',
  emergencyPlan: baseUrl + '/emergencyLibrary/list',
  emergencyDrillAssess: baseUrl + '/drillAssess',
  /** === INCIDENT === */
  incident: baseUrl + '/incident',
  orgContact: baseUrl + '/orgContact/list',
  incidentInvolved: baseUrl + '/incidentInvolved/list',
  incidentDiagnostic: baseUrl + '/incidentDiagnostic',
  incidentTasks: baseUrl + '/incidentTasks',
  incidentInvestigationDetailInfo: baseUrl + '/incidentInvestigationDetailInfo',
  incidentAuditAnalysis: baseUrl + '/incidentAuditAnalysis',
  incidentLossPersonnel: baseUrl + '/incidentLossPersonnel',
  incidentAuditPersonnelLoss: baseUrl + '/incidentAuditPersonnelLoss',
  // 需要更改
  incidentInquiry: baseUrl + '/incidentInquiry',
  abnormalEvent: baseUrl + '/abnormalEvent',
  incidentInvestigation: baseUrl + '/incidentInvestigation',
  incidentPersonalReportDetailedInfo: baseUrl + '/incidentPersonalReportDetailedInfo',
  personalReportBasicInfo: baseUrl + '/personalReportBasicInfo',
  incidentPersonalReportCauseAnalisys: baseUrl + '/incidentPersonalReportCauseAnalisys',
  incidentTaskContent: baseUrl + '/incidentTaskContent',
  baseUrl: baseUrl,
  hazard: baseUrl + '/hazard',
  /** === System Setting === */
  organization: baseUrl + '/corporates',
  formManagement: baseUrl + '/forms',
  announcement: baseUrl + '/announcement',
  /** === INSPECTION === */
  inspection: baseUrl + '/inspections',
  deviations: baseUrl + '/deviations',
  inspectionTasks: baseUrl + '/inspectionTasks',
  /** === DASHBOARDCHART === */
  dashboardChart: baseUrl + '/dashboardChart',
  emergencyDashboardChart: baseUrl + '/emergency/dashboard',
  /** === COMPLIANT DOCUMENT */
  compliantDocumentCategory: baseUrl + '/compliantDocumentCategory',
  compliantDocument: baseUrl + '/compliantDocument',
  /** === ROLES * === */
  roles: baseUrl + '/roles',
  menus: baseUrl + '/menus',
  message: baseUrl + '/message',
  /** === Government Inspection === */
  governmentInspection: baseUrl + '/inspections/government',
  /** === Government Penalty === */
  governmentPenalty: baseUrl + '/governments/punishment',
  /** === System Announcement === */
  SystemAnnouncement: baseUrl + '/systemAnnouncement/list',
  SystemAnnouncementRegulation: baseUrl + '/systemAnnouncement/get/',
  space: baseUrl + '/space',
};

export const API = api;
