// English
export const locale = {
  lang: 'en',
  data: {
    MENU: {
      TASK_DETAIL: 'Task Detail',
      TASK_CONTENT: 'Task Content',
      DASHBOARD: 'Dashboard',
      DASHBOARD_REP: 'Module Report',
      DASHBOARD_TRA: 'Training Dashboard',
      DASHBOARD_RIS: 'Risk Dashboard',
      DASHBOARD_REG: 'Regulatory Dashboard',
      DASHBOARD_INC: 'Incident Dashboard',
      DASHBOARD_ISS: 'Issue Dashboard',
      DASHBOARD_EME: 'Emergency Dashboard',
      SYSTEM_SETUP: 'System Setup',
      TAS110: 'Personal Task Center',
      TAS111: 'View All Tasks',
      TAS112: 'Unclaimed Tasks',
      TAS113: 'My Task',
      TRA100: 'Training and License',
      TRA110: 'Training Schedule',
      TRA111: 'Training Calendar',
      TRA112: 'Dept Schedule',
      TRA113: 'Instructor',
      TRA114: 'Class Scheduling',
      TRA115: 'Training Calendar',
      TRA116: 'Exemption Utility',
      TRA117: 'Employee Training Record',
      TRA118: 'Course Record',
      TRA119: 'My Course',
      TRA120: 'Certificate Management',
      TRA121: 'Certificate Inquiry',
      TRA122: 'Certificate Category',
      TRA130: 'Training Record',
      TRA131: 'Unfinished Training',
      TRA132: 'Finished Training',
      TRA133: 'Conference Attendance',
      TRA134: 'My Course',
      TRA135: 'Employee Record',
      TRA140: 'Question Bank Management',
      TRA141: 'Question Set',
      TRA142: 'Quiz Management',
      TRA143: 'Question Management',
      TRA144: 'Course Scheduling',
      RIS100: 'Risk Assessment',
      RIS110: 'Risk Analysis',
      RIS111: 'Risk Matrix',
      RIS112: 'Job Risk inquiry',
      RIS120: 'Risk Control',
      RIS121: 'Planning List',
      RIS130: 'Risk Record',
      RIS131: 'Risk Assessment Record',
      RIS132: 'Correction Action',
      RIS140: 'Two Lists And Three Cards',
      RIS141: 'Hierarchical Control List Of Security Risks',
      RIS142: 'Identification List Of Risk Factors',
      RIS143: 'Three Cards Management',
      EME100: 'Emergency Management',
      EME110: 'Emergency Resource Management',
      EME111: 'Emergency Team',
      EME112: 'Emergency Supplies',
      EME120: 'Emergency Plan',
      EME121: 'Emergency Plan Library',
      EME122: 'Emergency Plan',
      EME130: 'Emergency Drills',
      EME131: 'Emergency Drill Plan',
      EME132: 'Emergency Drill Records',
      EME133: 'Emergency Drill Evaluation',
      EME134: 'Emergency Drill Plan Details',
      INC100: 'Incident Management',
      INC110: 'Accident Handling',
      INC111: 'Incident Report',
      INC112: 'Incident Inquiry',
      INC113: 'Incident Investigation',
      INC114: 'Corrective Action',
      INC120: 'Labor Statistics',
      INC121: 'Monthly Working Hours',
      INC122: 'Working Day Record',
      INC123: 'Working Hours Summary',
      GR100: 'Regulatory Compliance',
      GR110: 'Regulatory Database',
      GR111: 'Regulations Overview',
      GR112: 'Draft Overview',
      GR113: 'My Favorite',
      GR114: 'Company Applicable List',
      GR115: 'Upload Regulation Updates',
      GR120: 'Identification and Correction',
      GR121: 'Compliance Assessment',
      GR122: 'Identification Task',
      GR123: 'Corrective Action',
      SS100: 'System Setting',
      SS110: 'Basic Configuration',
      SS111: 'Organization',
      SS112: 'Employee Management',
      SS113: 'Facility',
      SS116: 'Role Management',
      SS120: 'Advanced Settings',
      SS121: 'System Code',
      SS130: 'Form Management',
      SS131: 'Question Bank',
      SS132: 'Forms',
      SS140: 'Notification Management',
      SS141: 'Announcement Management',
      SS142: 'Space Point Management',
      SS143: 'Physical Attribute Management',
      INS100: 'Inspection',
      INS110: 'Inspection Activity',
      INS111: 'Issue Reporting',
      INS112: 'Inspection Scheduling',
      INS113: 'Ad-hoc Inspection',
      INS120: 'Inspection Result',
      INS121: 'Inspection Record',
      INS122: 'Issue list',
      INS123: 'Corrective Action',
      INS130: 'Government Inspection',
      INS131: 'Government Inspection Reporting',
      INS132: 'Government Inspection Inquiry',
      INS133: 'Government Penalty',
      INS134: 'Government Inspection Correction',
      DOC100: 'Documentation Management',
      DOC110: 'Documentation Management',
      DOC111: 'Documentation Management',
      DOC112: 'Documentation List',
      DOC113: 'Documentation Classification',
      NM100: 'Notification',
      NM110: 'Information Management',
      NM111: 'Message Center',
      SS114: 'Employee Group',
      SS115: 'Announcement Management',
      INC125: 'Message Center',
      SCREEN_DASHBOARD: 'Screen Dashboard',
      DATA_OVERVIEW: 'Data Overview',
    },
    GENERAL: {
      ACTIONS: {
        LOGOUT: 'Logout',
        OK: 'OK',
        CLEAR: 'Clear',
        CANCEL: 'Cancel',
        CLOSE: 'Close',
        LEAVE: 'Leave',
        SUBMIT: 'Submit',
        RESET: 'Reset',
        SEARCH: 'Search',
        VIEW: 'View',
        ADD: 'Add',
        EDIT: 'Edit',
        EXPORT: 'Export',
        EXPORT_ALL: 'Export All',
        DELETE: 'Delete',
        UPDATE: 'Update',
        SAVE: 'Save',
        SAVE_SUBMIT: 'Save & Submit',
        DO_NOT_SAVE: 'Do Not Save',
        SAVE_CONFIRM: 'Save and Confirm',
        SAVE_LEAVE: 'Save and Leave',
        BACK: 'Back',
        PREVIOUS: 'Previous',
        NEXT: 'Next',
        NO: 'No',
        YES: 'Yes',
        CHOOSE: 'Choose',
        CLEAR_SELECTION: 'None',
        BACK_TO_LIST: 'Back To List',
        PUBLISH: 'Publish',
        ADD_NEW_VERSION: 'Add New Version',
        ADVANCED_SEARCH_SHOW: 'Show Advanced Search',
        ADVANCED_SEARCH_HIDE: 'Hide Advanced Search',
        SUBMIT_PREVIEW: 'Submit Preview',
        ASSIGN: 'Assign',
        CLAIM: 'Claim',
        EXPORT_EXCEL: 'Export Excel',
        DRAFT: 'Save As Draft',
        CLOSE_CASE: 'Close Case',
        REJECT: 'Reject',
        SHUTDOWN: 'Shutdown',
        END: 'End',
        PRINT: 'Print',
        COLUMN_SELECTOR: 'Columns',
        BACK_HOME: 'Back To Home',
        RELEASE: 'Release',
        SIGNOFFTASK: 'Sign Off Task'
      },
      TITLE: {
        DELETE: 'Delete',
        LEAVE: 'Leave site?',
        SURE: 'Clear report?',
        COLUMN_SELECTOR: 'Select display column',
        RELEASE: 'Release',
      },
      MSG: {
        RELEASE: 'Are you sure to Release?',
        DELETE: 'Record(s) will be deleted and cannot undo. Are you sure to continue?',
        CONFIRM_LEAVE: 'There might be unsaved content. Are you sure to leave?',
        LEAVE: 'Changes you made may not be saved.',
        SURE: 'Are you sure to clear the report?',
        UNFILLED_FIELD: 'Unable to save, there are unfilled fields.',
        UNFILLED_FIELD_SUBMIT: 'Unable to submit, there are unfilled fields.',
        FIELD_EMPTY: 'Please filled out all necessary fileds',
        CREATE_SUCCESS: 'Field/Option created',
        UPDATE_SUCCESS: 'Field/Option saved',
        DELETE_SUCCESS: 'Field/Option delete',
        SAVE_SUCCESS: 'Save Success',
        SUBMIT_SUCCESS: 'Submit Success',
        SEARCH_NECESSARY: 'Filter options that are required have not been filled in!',
        PUBLISH_SUCCESS: 'Publish Success',
        CLOSE_SUCCESS: 'Closed Succeess',
        PAGE_404: 'Sorry, the page you accessed is missing. Please contact admin.',
      },
      NONE: 'None',
      NO_RESULT: 'No result',
      PLACEHOLDER: {
        SEARCH: 'Enter to search',
        SELECT: 'Choose an option',
        CLARIFY: 'Please explain',
      },
      SELECT_REQUIRED: 'Please select an item',
      CHILDREN_ORG_INCLUDED: 'Children Organization Included',
      NO_ATTACHMENTS: 'No attachments uploaded.'
    },
    LABEL: {
      CHECKBOX_SELECTED: 'Selected'
    },
    DX: {
      NO_DATA_TEXT: 'No data',
      PAGER: {
        INFOTEXT: 'Page {0} of {1} ({2} items)'
      },
      UPLOADER: {
        SELECT_BTN_TEXT: 'Select File',
        LABEL_TEXT: 'or Drop file here',
        READY_MSG: 'Ready to upload',
        MAX_FILE_SIZE: 'Maximum file size',
        INVALID_MAX_FILE_SIZE_MSG: 'File is too large',
        IGNORE_OVERLARGE_FILE_HINT: 'Overlarge files won\'t be uploaded',
        RESET: 'Clear Selected File',
        DELETE: 'Delete File',
        CONFIRM_DELETE: 'Are you sure to delete this file?',
        FAILED_TO_UPLOAD: 'Failed to upload',
        IMPORT: 'Import',
        PICTURE_HINT: 'Please upload picture file with suffix of jpg, jpeg, gif or png. Picture file size under 2M is recommended.',
        FILE_INVALID: 'Selected file is not allowed. Plesase select attachments correctly.',
        INVALID_FILE_EXTENSION_MSG: 'File extension is not allowed',
      }
    },
    MAT: {
      PAGINATOR: {
        ITEMS_PER_PAGE: 'Items per page',
        FIRST: 'First page',
        LAST: 'Last page',
        PREVIOUS: 'Previous page',
        NEXT: 'Next page',
      },
    },
    HTTP_ERROR_MSG: {
      '401': 'Authentication failed. Please login',
      '404': 'System is offline or under maintainence. Please try again later',
      '413': 'Uploaded file was too large. Please try smaller file'
    }
  }
};
