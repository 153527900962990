// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

// Material
import { MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatInputModule } from '@angular/material';

// Translate
import { TranslateModule } from '@ngx-translate/core';

// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './reducers';
import { AuthEffects } from './effects/auth';

// Core component
import { AuthComponent } from './auth.component';

// Pages
import { LoginPage } from './pages/login/login';

// Components
import { AuthNoticeComponent } from './components/auth-notice/auth-notice';

// Services
import { AuthenticationService } from './services/authentication.service';
import { TokenStorage } from './services/token-storage.service';
import { UtilsService } from '@_core/services/utils.service';
import { AuthNoticeService } from '@metronic/core/auth/auth-notice/auth-notice.service';
import { RolePermissionService } from '@auth/services/role-permission.service';

// i18n
import { TranslationService } from '@quantum-theme/core/_base/layout/services/translation.service';
import { locales } from './i18n';

// permission
import { NgxPermissionsModule } from 'ngx-permissions';

export const EFFECTS = [
  AuthEffects,
];

export const SERVICES = [
  AuthenticationService,
  TokenStorage,
  UtilsService,
	AuthNoticeService,
	RolePermissionService
];

export const COMPONENTS = [
  // Core component
  AuthComponent,
  // Pages
  LoginPage,
  // Components
  AuthNoticeComponent,
];

const routes: Routes = [
	{
		path: '',
		component: AuthComponent,
		children: [
			{
				path: '',
				redirectTo: 'login',
				pathMatch: 'full'
			},
			{
				path: 'login',
				component: LoginPage,
				data: {returnUrl: window.location.pathname}
			}
		]
	}
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		RouterModule.forChild(routes),
		MatInputModule,
		MatFormFieldModule,
		MatCheckboxModule,
		TranslateModule.forChild(),
		StoreModule.forFeature('auth', reducers),
		EffectsModule.forFeature(EFFECTS),
		NgxPermissionsModule.forChild(),
	],
	providers: SERVICES,
	exports: COMPONENTS,
	declarations: COMPONENTS
})

export class AuthModule {
	static forRoot() {
		return {
			ngModule: AuthModule,
		};
	}

  constructor(
    private translationService: TranslationService,
  ) {
    this.translationService.loadTranslations(...locales);
  }
}
