// Traditional Chinese
export const locale = {
  lang: 'zh-Hant',
  data: {
    MENU: {
      TASK_DETAIL: '任務詳情',
      TASK_CONTENT: '任務內容',
      DASHBOARD: '儀表板',
      DASHBOARD_REP: '模組報表',
      DASHBOARD_TRA: '培訓儀表板',
      DASHBOARD_REG: '法規儀表板',
      DASHBOARD_RIS: '風險儀表板',
      DASHBOARD_INC: '事故儀表板',
      DASHBOARD_ISS: '隱患儀表板',
      DASHBOARD_EME: '應急儀錶板',
      SYSTEM_SETUP: '系統設定',
      TAS110: '個人任務中心',
      TAS111: '任務管理',
      TAS112: '待認領任務',
      TAS113: '我的任務-試用',
      TRA100: '培訓與證件',
      TRA110: '培訓计划',
      TRA111: '培训日历',
      TRA112: '部门培训',
      TRA113: '教師管理',
      TRA114: '班級排課',
      TRA115: '培訓日曆',
      TRA116: '豁免工具',
      TRA117: '员工紀錄',
      TRA118: '課程記錄',
      TRA119: '我的课程',
      TRA120: '證照管理',
      TRA121: '证件查询与录入',
      TRA122: '证件名称维护',
      TRA130: '培训记录',
      TRA131: '待完成培训',
      TRA132: '已完成培训',
      TRA133: '会议签到',
      TRA134: '我的课程',
      TRA135: '员工记录',
      TRA140: '题库管理',
      TRA141: '试题集管理',
      TRA142: '试卷管理',
      TRA143: '试题管理',
      TRA144: '培訓計劃',
      RIS100: '風險評估',
      RIS110: '風險分析',
      RIS111: '風險矩陣',
      RIS112: '作業風險查詢',
      RIS120: '風險控制',
      RIS121: '風險評估排程',
      RIS130: '風險紀錄',
      RIS131: '風險評估任務',
      RIS132: '矯正改善',
      RIS140: '兩單三卡',
      RIS141: '安全風險分級管控清單',
      RIS142: '危險因素排查辨識清單',
      RIS143: '三卡管理',
      EME100: '應急管理',
      EME110: '應急資源管理',
      EME111: '應急隊伍',
      EME112: '應急物資',
      EME120: '應急預案',
      EME121: '應急預案庫',
      EME122: '應急預案',
      EME130: '應急演練',
      EME131: '應急演練計劃',
      EME132: '應急演練記錄',
      EME134: '應急演練計劃詳情',
      EME133: '應急演練評估',
      INC100: '事故管理',
      INC110: '事故處理',
      INC111: '事故通報',
      INC112: '事故查詢',
      INC113: '事故調查',
      INC114: '矯正改善',
      INC120: '勞動統計',
      INC121: '每月工時填報',
      INC122: '法定工作日紀錄',
      INC123: '工時統計報表',
      GR100: '法規鑑別',
      GR110: '法規資料庫',
      GR111: '法規總覽',
      GR112: '草案總覽',
      GR113: '我的最愛',
      GR114: '公司適用清單',
      GR115: '上傳更新法規',
      GR120: '鑑別與改善',
      GR121: '查核活動',
      GR122: '鑑別任務',
      GR123: '矯正改善',
      SS100: '系統設定',
      SS110: '基本設定',
      SS111: '組織架構',
      SS112: '員工管理',
      SS113: '廠區管理',
      SS116: '角色管理',
      SS120: '進階設定',
      SS121: '系統代碼',
      SS130: '表格管理',
      SS131: '題庫',
      SS132: '表格',
      SS140: '通知管理',
      SS141: '公告管理',
      SS142: '空间点管理',
      SS143: '物理空间管理',
      INS100: '檢查管理',
      INS110: '檢查活動',
      INS111: '巡檢回報',
      INS112: '檢查排程',
      INS113: '立即檢查',
      INS120: '檢查結果',
      INS121: '檢查紀錄',
      INS122: '缺失發現',
      INS123: '矯正改善',
      INS130: '政府檢查',
      INS131: '政府檢查上報',
      INS132: '政府檢查查詢',
      INS133: '政府處罰',
      INS134: '政府檢查整改查詢',
      DOC100: '合規文件管理',
      DOC110: '合規文件管理',
      DOC111: '合規文件管理',
      DOC112: '合規文件清單',
      DOC113: '合規文件分類',
      NM100: '通知管理',
      NM110: '信息管理',
      NM111: '消息中心',
      SS114: '員工群組',
      SS115: '公告管理',
      INC125: '消息中心',
      SCREEN_DASHBOARD: '大屏報表',
      DATA_OVERVIEW: '數據統計概覽',
    },
    GENERAL: {
      ACTIONS: {
        LOGOUT: '登出',
        OK: '確認',
        CANCEL: '取消',
        CLOSE: '關閉',
        LEAVE: '離開',
        SUBMIT: '提交',
        RESET: '重設',
        SEARCH: '搜尋',
        VIEW: '檢視',
        ADD: '新增',
        EDIT: '編輯',
        EXPORT_ALL: '全部匯出',
        EXPORT: '匯出',
        DELETE: '刪除',
        UPDATE: '更新',
        SAVE: '儲存',
        DO_NOT_SAVE: '不儲存',
        SAVE_CONFIRM: '儲存並確認',
        SAVE_LEAVE: '儲存並離開',
        SAVE_SUBMIT: '儲存並提交',
        BACK: '返回',
        PREVIOUS: '上一步',
        NEXT: '下一步',
        NO: '否',
        YES: '是',
        CLEAR_SELECTION: '無',
        BACK_TO_LIST: '回列表頁',
        PUBLISH: '發布',
        CHOOSE: '選擇',
        CLEAR: '清除',
        ADD_NEW_VERSION: '發布',
        ADVANCED_SEARCH_SHOW: '顯示進階搜尋',
        ADVANCED_SEARCH_HIDE: '隱藏進階搜尋',
        SUBMIT_PREVIEW: '提交预览',
        ASSIGN: '轉派',
        CLAIM: '認領',
        EXPORT_EXCEL: '导出Excel',
        DRAFT: '保存草稿',
        CLOSE_CASE: '结案',
        REJECT: '駁回',
        SHUTDOWN: '终止',
        END: '结束',
        PRINT: '打印',
        COLUMN_SELECTOR: '欄位',
        BACK_HOME: '返回首頁',
        RELEASE: '发布',
        SIGNOFFTASK: '簽核任務'
      },
      TITLE: {
        DELETE: '確認刪除',
        LEAVE: '確認離開?',
        SURE: '確認清除回報',
        COLUMN_SELECTOR: '選擇顯示欄位',
        RELEASE: '确认发布',
      },
      MSG: {
        RELEASE: '确认要发布?',
        DELETE: '刪除後紀錄將會清空且無法復原，確定要刪除？',
        LEAVE: '您尚未儲存此筆資料，請問您確定離開？',
        CONFIRM_LEAVE: '当前页面可能存在未保存内容，您确定离开吗？',
        SURE: '確認清除事故回報嗎？',
        UNFILLED_FIELD: '無法儲存，有尚未填寫的欄位。',
        FIELD_EMPTY: '必填項未填寫，請補全',
        CREATE_SUCCESS: '添加成功',
        UPDATE_SUCCESS: '更新成功',
        DELETE_SUCCESS: '刪除成功',
        SAVE_SUCCESS: '保存成功',
        SUBMIT_SUCCESS: '提交成功',
        SEARCH_NECESSARY: '必填的搜索項未選擇，無法搜索！',
        PUBLISH_SUCCESS: '發佈成功',
        CLOSE_SUCCESS: '終止成功',
        PAGE_404: '抱歉，您訪問的頁面不存在，請聯繫管理員。',
      },
      NO_RESULT: '沒有結果',
      NONE: '無',
      PLACEHOLDER: {
        SEARCH: '輸入以搜尋',
        SELECT: '請選擇',
        CLARIFY: '請說明',
      },
      SELECT_REQUIRED: '請選擇一項',
      CHILDREN_ORG_INCLUDED: '包含子架構',
      NO_ATTACHMENTS: '未上傳附件'
    },
    LABEL: {
      CHECKBOX_SELECTED: '已選擇'
    },
    DX: {
      NO_DATA_TEXT: '無資料',
      PAGER: {
        INFOTEXT: '第 {0} 頁，共 {1} 頁 (共 {2} 項)'
      },
      UPLOADER: {
        SELECT_BTN_TEXT: '選擇檔案',
        LABEL_TEXT: '或拖曳檔案至此',
        READY_MSG: '準備上傳',
        MAX_FILE_SIZE: '檔案大小限制',
        INVALID_MAX_FILE_SIZE_MSG: '檔案過大',
        IGNORE_OVERLARGE_FILE_HINT: '過大的檔案將不會上傳',
        RESET: '清除已選檔案',
        DELETE: '刪除檔案',
        CONFIRM_DELETE: '您確定要刪除此檔案嗎？',
        FAILED_TO_UPLOAD: '未能成功上傳',
        IMPORT: '导入',
        PICTURE_HINT: '请上傳jpg, jpeg, gif, png格式的圖片,建议圖片大小不超过2M',
        FILE_INVALID: '上傳的文件不符合要求，請重新選擇',
        INVALID_FILE_EXTENSION_MSG: '文件格式不符合要求',
      }
    },
    MAT: {
      PAGINATOR: {
        ITEMS_PER_PAGE: '每頁',
        FIRST: '第一頁',
        LAST: '最後一頁',
        PREVIOUS: '上一頁',
        NEXT: '下一頁',
      },
    },
    HTTP_ERROR_MSG: {
      '401': '認證失效，請重新登入',
      '404': '系統下線或維護中，請稍候再試',
      '413': '上傳檔案過大，請嘗試較小檔案'
    }
  }
};
