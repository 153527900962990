import { NgModule } from '@angular/core';
import { CoreModule } from '@metronic/core/core.module';
import { PartialsModule } from '@metronic/views/partials/partials.module';

// Customize
import { PagesModule } from './views/pages/pages.module';
import { InterceptService } from '@_core/services/intercept.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  imports: [
    CoreModule,
    PagesModule,
    PartialsModule
  ],
  exports: [
    CoreModule,
    PagesModule,
    PartialsModule
  ],
  providers: [
    
  ]
})
export class QuantumThemeModule {
}
