// Simplified Chinese
export const locale = {
  lang: 'zh-Hans',
	data: {
    AUTH: {
			GENERAL: {
				OR: '或',
				SUBMIT_BUTTON: '提交',
				NO_ACCOUNT: '没有帐号吗？',
				SIGNUP_BUTTON: '注册',
				FORGOT_BUTTON: '忘记密码',
				BACK_BUTTON: '返回',
				PRIVACY: '隐私权政策',
				LEGAL: '法律',
				CONTACT: '联系我们',
			},
			LOGIN: {
				TITLE: '登入帐号',
				BUTTON: '登入',
			},
			FORGOT: {
				TITLE: '忘记密码？',
				DESC: '输入电子邮箱以重设您的密码',
			},
			REGISTER: {
				TITLE: '注册',
				DESC: '输入资料以创建一个新帐号',
				SUCCESS: '已成功注册帐号。使用此帐号登入'
			},
			INPUT: {
				USER_NAME: '用户名称',
				EMAIL: '电子邮箱',
				FULLNAME: '用户全名',
				PASSWORD: '密码',
				CONFIRM_PASSWORD: '确认密码',
			},
			VALIDATION: {
				INVALID: '{{name}}不正确',
				REQUIRED: '{{name}}为必填',
				MIN_LENGTH: '{{name}}的最小长度为 {{min}}',
				AGREEMENT_REQUIRED: '需同意使用者条款',
				NOT_FOUND: '没有这个{{name}}',
				INVALID_LOGIN: '登入信息错误'
			}
		}
  }
};
